import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchBillList, updateSet, updateAccount, fetchSet } from "@/api/finance";
import "@/utils/global.js";
import { downloadFun, transBillState } from "@/utils/util";
import Pagination from "@/components/Pagination";
import { getToken } from "@/utils/cache";
export default {
  name: "orderList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: "0"
        }
      },
      lineStatus: _global.lineStatus,
      orderState: _global.orderState,
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      btnLoading: false,
      selectCount: 0,
      selectList: [],
      memberDialog: false,
      dialogForm: {
        first: "",
        other: ""
      },
      dataDialog: false,
      totalNum: 0,
      total: 0
    };
  },
  created: function created() {
    this.getTableData();
    this.getSet();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchBillList(data).then(function (response) {
        console.log(response);
        _this.totalNum = response.meta.total_money;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.total = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "error",
          message: err.data["message"]
        });
      });
    },
    getSet: function getSet() {
      var _this2 = this;

      fetchSet().then(function (res) {
        _this2.dialogForm["first"] = res.data.first;
        _this2.dialogForm["other"] = res.data.other;
      });
    },
    handleSearch: function handleSearch() {
      this.searchForm.page = 1;
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.getTableData();
    },
    handleDownload: function handleDownload() {
      console.log("导出");
      this.btnLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/downloads/commission_bill?token=".concat(getToken(), "&keyword=").concat(data.keyword || "");
      console.log(url);
      downloadFun(url, "订单列表.csv");
      this.btnLoading = false;
    },
    handleReturnBtn: function handleReturnBtn() {
      var _this3 = this;

      this.$refs["dialogForm"].validate(function (valid) {
        if (valid) {
          var data = _this3.dialogForm;
          updateSet(data).then(function (res) {
            console.log(res);
            _this3.dataDialog = false;

            _this3.$message({
              type: "success",
              message: "佣金设置成功"
            });
          }).catch(function (err) {
            _this3.$message({
              type: "warning",
              message: err.data["message"]
            });
          });
        }
      });
    },
    handleSetting: function handleSetting(row) {
      var _this4 = this;

      this.dataDialog = true;
      this.$nextTick(function () {
        _this4.$refs["dialogForm"].clearValidate();
      });
    },
    handleEnd: function handleEnd(row) {
      var _this5 = this;

      console.log("结束订单");
      var content = "\u60A8\u786E\u8BA4\u8981\u7ED3\u7B97\u8BE5".concat(row.manager.name, "\u8D26\u5355?");
      var title = "结算账单";
      this.$confirm(content, title, {
        confirmButtonText: "提交",
        cancelButtonText: "取消"
      }).then(function () {
        var data = row.id;
        updateAccount(data).then(function (res) {
          console.log(res);

          _this5.$message({
            type: "success",
            message: "结算账单成功"
          });

          _this5.getTableData();
        }).catch(function (err) {
          _this5.$message({
            type: "warning",
            message: err.data["message"]
          });
        });
      }).catch(function () {});
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    transBillState: transBillState
  }
};